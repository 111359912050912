import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { EquipmentModel, EquipmentSupplierModel } from '../models/equipment.model';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  conditionalRequiredValidator(conditionFn: () => boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (conditionFn()) {
        if (!control.value) {
          return { required: true };
        }
      }
      return null;
    };
  }

  getSupplierName(id: string, equipment: EquipmentModel[], supplier: EquipmentSupplierModel[]): string {
    const equipmentSupplier = equipment.find((e) => e.supplierId === id);
    let supplierId: number = 1;
    if (equipmentSupplier) {
      supplierId = equipmentSupplier.supplier;
    }

    return supplier.find((sup) => sup.id === supplierId).name;
  }

  calculateCondition(date: string): number {
    // calc based on date, we use it to evaluate the position received of a vessel
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - new Date(date).getTime();
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    // Check if the time difference is greater than 2 hours
    if (hoursDifference > 2 && hoursDifference <= 11) {
      return 3;
    }
    if (hoursDifference > 12) {
      return 5;
    }
    return 1;
  }

  calculateConditionOnHoursToday(givenDate: Date, startHour: number, endHour: number) {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth(); // Note: getMonth() is zero-based (0 = January)
    const day = today.getDate();

    // Create new Date objects for 3 AM and 9 AM today
    const dateHourStart = new Date(year, month, day, startHour, 0, 0);
    const dateHourEnd = new Date(year, month, day, endHour, 0, 0);

    // Get the time of the given date (ignore the day, month, and year)
    const givenHours = givenDate.getHours();
    const givenMinutes = givenDate.getMinutes();
    const givenSeconds = givenDate.getSeconds();

    // Create a new Date object for the current day but with the time from the given date
    // const givenDateToday = new Date(year, month, day, givenHours, givenMinutes, givenSeconds);

    // Check if date is from today
    const isToday =
      givenDate.getDate() === today.getDate() &&
      givenDate.getMonth() === today.getMonth() &&
      givenDate.getFullYear() === today.getFullYear();

    // Check if the given date's time is between 3 AM and 9 AM today
    if (givenDate >= dateHourStart && givenDate <= dateHourEnd) {
      console.log(givenDate, dateHourEnd, dateHourStart);
      return 1; // OK -> green
    } else if (isToday && givenDate > dateHourEnd) {
      return 3; // After end time but still today -> yellow
    } else {
      return 5; // critical -> red
    }
  }
}
