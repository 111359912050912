import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, NgIf, MatButton]
})
export class SnackbarComponent {
  message = '';
  action = '';
  duration: 3;
  progress = 0;
  private intervalId: any;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) data: any,
    private snackBarRef: MatSnackBarRef<SnackbarComponent>
  ) {
    this.message = data.message;
    this.action = data.action;
    this.duration = data.duration || this.duration;
  }

  ngOnInit() {
    const step = 100 / (this.duration * 10); // Calculate step based on duration
    this.intervalId = setInterval(() => {
      this.progress += step;
      if (this.progress >= 100) {
        clearInterval(this.intervalId);
      }
    }, 100); // Update every 100ms
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  handleClose() {
    this.snackBarRef.dismiss();
  }
}
