import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MemoryService } from './services/memory.service';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private memory: MemoryService,
    private router: Router,
    private routeService: RouteService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    return this.checkUserLogin(next, url);
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: string): boolean {
    if (this.memory.isLoggedIn()) {
      const userCapabilities = this.memory.getCapabilities();
      const requiredCapabilities = route.data.capabilities || [];
      const loginData = this.memory.getLoginData();

      // Check if user has at least one required capability or the wildcard capability
      const hasAccess = this.hasRequiredCapabilities(userCapabilities, requiredCapabilities);

      if (!hasAccess) {
        this.redirectToEntryOrDashboard(loginData.role?.entryUri, url);
        return false;
      }

      if (url === '/user/dashboard' && loginData.role?.entryUri) {
        this.router.navigate([loginData.role.entryUri]);
        return true;
      }
      return true;
    }

    this.routeService.setAttemptedRoute(decodeURIComponent(url));
    this.router.navigate(['/auth/login']);
    return false;
  }

  private hasRequiredCapabilities(userCapabilities: string[], requiredCapabilities: string[]): boolean {
    if (userCapabilities.includes('all')) {
      return true;
    }
    if (requiredCapabilities.length > 0) {
      return requiredCapabilities.some(
        (capability: string) =>
          userCapabilities.includes(capability) || userCapabilities.includes(capability.split('.')[0] + '.*')
      );
    }
    return true;
  }

  private redirectToEntryOrDashboard(entryUri?: string, currentUrl?: string): void {
    if (entryUri) {
      this.router.navigate([entryUri]);
    } else if (currentUrl !== '/user/dashboard') {
      this.router.navigate(['/user/dashboard']);
    }
  }
}
