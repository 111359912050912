<span class="custom-snackbar" *transloco="let t">
  <span>{{ message }}</span>
  @if (action) {
    <span class="flex-grow-1"></span>
  }
  @if (action) {
    <span class="snack-action">
      <button mat-button (click)="handleClose()">{{ action }}</button>
    </span>
  }
  <div class="snackbar-progress" [style.width.%]="progress"></div>
</span>
