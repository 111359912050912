<div *ngIf="size !== 'small'" class="flex align-center">
  <div class="profile-picture-container" [class.large-container]="size === 'large'">
    <img *ngIf="user" src="{{ profilePictureLink }}" />
  </div>
  <div>
    <b>{{ user?.forename }} {{ user?.surname }}</b
    ><br />
    <span class="flex">
      <ng-container *ngIf="user?.userLogin?.siteUser?.siteId">
        <mat-icon
          [class.text-success]="user.userLogin.siteUser.isActive()"
          [class.text-muted]="!user.userLogin.siteUser.isActive()"
          [matTooltip]="user.userLogin.siteUser.isActive() ? 'zur Zeit anwesend' : 'zur Zeit nicht anwesend'"
          >domain</mat-icon
        >
        {{ (user.userLogin.siteUser.siteId | getSite | async)?.name }},
      </ng-container>
      {{ user?.userLogin?.role?.name }}
    </span>
    <mat-chip-listbox>
      <mat-chip-option
        class="chip-small"
        *ngFor="let group of user?.userLogin?.userGroups"
        [routerLink]="['/user/list']"
        [queryParams]="{ filter: stringify([{ x_userGroupId: [group.userGroupId] }]) }"
      >
        <mat-icon *ngIf="group.parent?.icon">{{ group.parent.icon }}</mat-icon> {{ group.name }}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>
<div *ngIf="size === 'small'" class="user-container">
  {{ user?.forename }} {{ user?.surname }}
  <span *ngIf="user?.userLogin?.role?.name"> ({{ user?.userLogin?.role?.name }})</span>
</div>
