import { Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { Capability } from './interfaces/user/capability.interface';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./views/authentication/authentication-views.module').then((m) => m.AuthenticationModule)
  },
  {
    path: 'assets',
    loadChildren: () => import('./views/assets/assets-views.module').then((m) => m.AssetViewsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'calendar',
    loadChildren: () => import('./views/calendar/calendar-views.module').then((m) => m.CalendarModule),
    canActivate: [AuthGuard],
    data: {
      capabilities: [Capability.View.Calendar]
    }
  },
  {
    path: 'emergency',
    loadChildren: () => import('./views/emergency/emergency-views.component').then((m) => m.EmergencyViewsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'equipment',
    loadChildren: () => import('./views/equipment/equipment.module').then((m) => m.EquipmentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inspector',
    loadChildren: () => import('./views/inspector/inspector-views.module').then((m) => m.InspectorViewsModule),
    canActivate: [AuthGuard],
    data: {
      capabilities: [Capability.View.Inspector]
    }
  },
  {
    path: 'management',
    loadChildren: () => import('./views/management/management-views.module').then((m) => m.SMVViewsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'support',
    loadChildren: () => import('./views/support/support-views.module').then((m) => m.SupportViewsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./views/user/user-views.module').then((m) => m.UserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vessel',
    loadChildren: () => import('./views/site-vessel/site-views.module').then((m) => m.SiteViewsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'visits-reports',
    loadChildren: () =>
      import('./views/visits-reports/visit-report-views.module').then((m) => m.VisitReportViewsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'warehouse',
    loadChildren: () => import('./views/warehouse/warehouse.module').then((m) => m.WarehouseViewsModule),
    canActivate: [AuthGuard],
    data: {
      capabilities: [Capability.View.Warehouse]
    }
  },
  { path: '', redirectTo: '/user/dashboard', pathMatch: 'full' },
  { path: 'not-found', component: NotFoundComponent },
  {
    path: '**',
    loadComponent: () => import('./components/not-found/not-found.component').then((m) => m.NotFoundComponent)
  }
];
